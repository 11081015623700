.App {
  text-align: center;
}

.App {
  display: flex;
  height: 100vh;
}

.content {
  flex-grow: 1;
  padding: 20px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: inline;
  margin-right: 10px;
}

nav a {
  text-decoration: none;
  color: blue;
}

nav a:hover {
  text-decoration: underline;
}

.App {
  display: flex;
  height: 100vh;
}

.content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 200px;
  transition: margin-left 0.3s ease;
}

.sidebar.closed + .content {
  margin-left: 0;
}

/* Add this CSS to your App.css or a suitable CSS file */
.sign-out-button {
  display: flex;
  align-items: center;
  background-color: #ff4b5c;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out-button:hover {
  background-color: #ff1c39;
}

.sign-out-button svg {
  margin-right: 8px;
}
