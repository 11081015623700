/* App.css */
.imageApp {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .box {
    padding: 20px;
    border: 1px solid #ccc;
    display: inline-block;
    margin-top: 50px;
    border-radius: 8px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 90%;
    max-width: 400px;
  }
  
  .image-preview {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .image-url {
    word-break: break-all;
    color: #333;
  }
  
  .upload-form label {
    display: block;
    margin: 10px 0;
  }
  
  .submit-button,
  .close-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover,
  .close-button:hover {
    background-color: #0056b3;
  }
  