.table-responsive {
    overflow-x: auto;
  }
  .table-responsive table {
    white-space: nowrap;
  }

  .custom-paper {
    overflow: scroll !important;
  }

  .table-container {
    overflow: scroll !important;
    max-width: 94vw !important;
  }


  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-17yl0n3-MuiPaper-root {
    border-collapse: collapse;
    max-width: 94vw !important;
    max-height: 500px;
    min-width: 600px; /* Set a minimum width for the table to ensure scroll effect */
  }
  
  .dynamic-table th, .dynamic-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }