.sidebar {
  width: 250px; /* Increase width for better layout */
  background-color: #000000;
  color: #ecf0f1;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease;
  z-index: 1000; /* Ensure the sidebar is above other content */
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar .toggle-button {
  position: absolute;
  top: 10px;
  right: -45px; /* Adjust based on icon size */
  background-color: #000000;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: #ecf0f1;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
  transition: background-color 0.3s ease;
}

.sidebar .toggle-button:hover {
  background-color: #002fff;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 15px;
}

.sidebar li a {
  text-decoration: none;
  color: #ecf0f1;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.sidebar li a .fa-icon {
  margin-right: 10px;
}

.sidebar li a:hover {
  text-decoration: underline;
}
