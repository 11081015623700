/* Ensure equal height for all columns */
.equal-height {
    display: flex;
  }
  
  /* Make cards take full height of their parent */
  .card {
    display: flex;
    flex-direction: column;
  }
  
  .card-body {
    flex: 1; /* Ensure body expands to take remaining space */
  }
  